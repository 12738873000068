import * as React from "react"
import { graphql, useStaticQuery } from "gatsby";
import { getImage, StaticImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import loadable from "@loadable/component";

import Layout from "../components/Layout"
import '../styles/index.css'

const Map = loadable(() => import("../components/react-map/App"));


const IndexPage = () => {

  const { phoneBackgroundSrc, doctorWithPatientSrc, arizonaMapBackgroundSrc, phoneMockupSrc } = useStaticQuery(graphql`
    query {
      phoneBackgroundSrc: file(relativePath: { eq: "phoneBackground.jpeg" }) {
        childImageSharp {
            gatsbyImageData(
              placeholder: NONE
              formats: [AUTO, WEBP]
              quality: 80
            )
          }
        }
        doctorWithPatientSrc: file(relativePath: { eq: "doctorWithPatient.jpg" }) {
          childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        arizonaMapBackgroundSrc: file(relativePath: { eq: "SoCal-Map.png" }) {
          childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP]
              )
            }
          }
        phoneMockupSrc: file(relativePath: { eq: "SoCal-Phone.png" }) {
          childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP]
              )
            }
          }
      }`);

    const phoneBackground = getImage(phoneBackgroundSrc)
    const doctorWithPatient = getImage(doctorWithPatientSrc)
    const arizonaMapBackground = getImage(arizonaMapBackgroundSrc);
    const phoneMockup = getImage(phoneMockupSrc)

    const footerBackgroundImageStack = [
      arizonaMapBackground,
      "linear-gradient(180deg, hsla(0, 0%, 100%, 0.6), hsla(0, 0%, 100%, 0.6))",
      phoneMockup
    ].reverse();


    // fluid {
    //   ...GatsbyImageSharpFluid_withWebp_noBase64
    // }

//      logo: allImageSharp(filter: {original: {src: {regex: "/logo/"}}}) {
//        edges {
//          node {
//            gatsbyImageData(width: 225, layout: CONSTRAINED, placeholder: BLURRED)
//          }
//        }
//      }

  return (
    <Layout>
      <div id="Top" className="back-to-top-container">
        <div className="back-to-top-button-container">
        
        </div>
      </div>
      <div className="section hero">
        <div className="columns w-row">
          <div className="w-col w-col-6 w-col-stack">
            <div className="full-width-col">
            <div className="hero-inner">
                <StaticImage
                src="../images/logo.png"
                placeholder="none"
                layout="fixed"
                width={225}
                alt="Dignity Health logo"
                formats={["auto", "webp", "avif"]}/>
                <div>
                  <h1 className="display-heading-1">Where do you go when you want info on preparing for an emergency? Here.</h1>
                </div>
                <h2 className="heading-3">Right now, you can take the first step to being prepared by downloading our virtual contact card.</h2>
              </div>
            </div>
          </div>
          <div className="column-2 w-col w-col-6 w-col-stack">
            <div className="full-width-col">
              <BgImage image={phoneBackground} className="download-box" alt="">
                <div className="hero-text-block">
                  <h2 className="heading-2 white large-desktop">Download our Urgent Care Contact Card to your phone now. And stay prepared.</h2>
                  <div className="download-text-box top">
                    <StaticImage
                      className="number"
                      src="../images/1.png"
                      placeholder="tracedSVG"
                      layout="fixed"
                      width={60}
                      quality={60}
                      alt="Number one"
                      formats={["auto", "webp", "avif"]}
                      />
                    <div className="heading-2 white">Find your location</div>
                  </div>
                  <div className="download-text-box top">
                  <StaticImage
                      className="number"
                      src="../images/2.png"
                      placeholder="tracedSVG"
                      layout="fixed"
                      width={60}
                      quality={60}
                      alt="Number two"
                      formats={["auto", "webp", "avif"]}/>
                    <div className="heading-2 white">Send the Urgent Care Contact Card to your phone</div>
                  </div>
                  <div className="download-text-box top last">
                    <StaticImage
                      className="number"
                      src="../images/3.png"
                      placeholder="tracedSVG"
                      layout="fixed"
                      alt="Number three"
                      width={60}
                      quality={60}
                      formats={["auto", "webp", "avif"]}/>
                    <div className="heading-2 white last">Breathe<span className="emdash">—</span>you're on your way to being prepared</div>
                  </div>
                </div>
              </BgImage>
            </div>
          </div>
        </div>
      </div>
      <div id="map-section" className="section map">
        <Map/>
      </div>
      <div className="section headline-only">
        <div className="wide-container w-container">
          <h2 className="heading-1 large large-tablet large-mobile">Dignity Health not only provides one-on-one care, we make it easier to find.</h2>
        </div>
      </div>
      <div>
        <div className="w-row">
          <div className="column w-col w-col-4 w-col-stack">
            <div className="div-block-5">
              <div className="icon-card l">
                <StaticImage
                      className="icon-bar"
                      src="../images/er-card-2.png"
                      placeholder="tracedSVG"
                      layout="fixed"
                      alt="A symbol of a Contact Card for a smartphone"
                      height={100}
                      width={100}
                      quality={20}
                      formats={["auto", "webp", "avif"]}/>
                <h2 className="heading-3 white">Set up Urgent Care Card in your phone.</h2>
              </div>
            </div>
          </div>
          <div className="column w-col w-col-4 w-col-stack">
            <div className="div-block-6">
              <div className="icon-card">
                <StaticImage
                      className="icon-bar"
                      src="../images/schedule-2.png"
                      placeholder="tracedSVG"
                      layout="fixed"
                      alt="A symbol of a clock"
                      height={100}
                      width={100}
                      quality={60}
                      formats={["auto", "webp", "avif"]}/>
                <h2 className="heading-3 white">When you need us, schedule online.</h2>
              </div>
            </div>
          </div>
          <div className="column w-col w-col-4 w-col-stack">
            <div className="div-block-7">
              <div className="icon-card">
                <StaticImage
                      className="icon-bar"
                      src="../images/er-mobile-2.png"
                      placeholder="tracedSVG"
                      layout="fixed"
                      alt="A symbol of a smartphone"
                      height={100}
                      width={100}
                      quality={60}
                      formats={["auto", "webp", "avif"]}/>
                <div>
                  <h2 className="heading-3 white">At our Urgent Care, we’re here and ready.<br /></h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section hero gray">
        <div className="columns w-row">
          <div className="w-col w-col-6 w-col-stack">
            <BgImage image={doctorWithPatient} className="download-box" />
          </div>
          <div className="w-col w-col-6 w-col-stack">
            <div className="full-width-col _2">
              <h2 className="display-heading-1 _2 large large-tablet large-mobile">The first promise we make is that you'll never face an emergency alone.</h2>
              <h2 className="heading-3">One-on-one healing. It's the way we practice medicine.</h2>
              <StaticImage
                      className="hhk"
                      src="../images/hhk-lockup.png"
                      placeholder="none"
                      layout="constrained"
                      width={410}
                      quality={100}
                      alt="Hello humankindness"
                      formats={["auto", "webp", "avif"]}/>
            </div>
          </div>
        </div>
      </div>
      <div className="section headline-only">
        <div className="wide-container w-container">
          <h2 className="heading-1 large large-tablet large-mobile">Just like any trip, there's a checklist. Or two.</h2>
        </div>
      </div>
      <div className="section hero orange">
        <div className="columns w-row">
          <div className="w-col w-col-6 w-col-stack">
            <div className="full-width-col l-block-copy">
              <div className="list-box">
                <h2 className="headline-1 white">Common Reasons for Urgent Care Visits</h2>
                <div className="download-text-box-container">
                  <div className="download-text-box l w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Asthma</p>
                  </div>
                  <div className="download-text-box l w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Allergies</p>
                  </div>
                  <div className="download-text-box l w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Back Pain</p>
                  </div>
                  <div className="download-text-box l w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Colds</p>
                  </div>
                  <div className="download-text-box l w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Congestion</p>
                  </div>
                  <div className="download-text-box l w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Coughs</p>
                  </div>
                  <div className="download-text-box l w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Earache</p>
                  </div>
                  <div className="download-text-box r w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Fever</p>
                  </div>
                  <div className="download-text-box r w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Food Poisoning</p>
                  </div>
                  <div className="download-text-box r w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Indigestion</p>
                  </div>
                  <div className="download-text-box r w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Migraines</p>
                  </div>
                  <div className="download-text-box r w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Minor Cuts</p>
                  </div>
                  <div className="download-text-box r w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Minor Sunburn</p>
                  </div>
                  <div className="download-text-box r w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Wheezing</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-col w-col-6 w-col-stack">
            <div className="full-width-col r-block">
              <div className="list-box">
                <h2 className="headline-1 white">Common Reasons for Emergency Room Visits</h2>
                <div className="download-text-box-container">
                  <div className="download-text-box l w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Asthma Attacks</p>
                  </div>
                  <div className="download-text-box l w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Allergic Reactions</p>
                  </div>
                  <div className="download-text-box l w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Chest Pain</p>
                  </div>
                  <div className="download-text-box l w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Difficulty Breathing</p>
                  </div>
                  <div className="download-text-box l w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Falls</p>
                  </div>
                  <div className="download-text-box l w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Fractures</p>
                  </div>
                  <div className="download-text-box l w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Head Trauma</p>
                  </div>
                  <div className="download-text-box r w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Issues While Pregnant</p>
                  </div>
                  <div className="download-text-box r w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Life-Threatening Infections</p>
                  </div>
                  <div className="download-text-box r w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Seizures</p>
                  </div>
                  <div className="download-text-box r w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Serious Cuts</p>
                  </div>
                  <div className="download-text-box r w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Severe Injury</p>
                  </div>
                  <div className="download-text-box r w-clearfix">
                                  <StaticImage
                src="../images/check-2.png"
                alt=""
                placeholder="none"
                layout="fixed"
                width={30}
                className="icon"
                role="presentation"
                formats={["auto", "webp", "avif"]}/>
                    <p className="heading-4">Stroke Symptoms</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="section bottom"> */}
      <BgImage className="section bottom" image={footerBackgroundImageStack} >
        <div className="w-container">
          <div className="div-block-8">
            <h2 className="headline-1 large large-tablet large-mobile">Did you <br />find the closest <br />Urgent Care Contact Card?</h2>
            <a href="#map-section" aria-label="Back to map" className=" w-button">Get It Now</a>
          </div>
        </div>
      </BgImage>
      {/* </div> */}
      {/* <BgImage className="section bottom" image={footerBackgroundImageStack} /> */}
      <div className="footer-1">
        <div className="footer-1-top">
          <div className="main-container">
            <div className="footer-1-top-row">
              <div className="footer-1-logo w-inline-block">
                <StaticImage
                  src="../images/logo.png"
                  placeholder="none"
                  layout="fixed"
                  width={160}
                  alt="Dignity Health logo"
                  className="image-3"
                  formats={["auto", "webp", "avif"]}/>
              </div>
              <div>
                <a href="https://terms.dignityhealth.org/cm/content.asp?pid=4&lid=4&facility_id=1&_ga=2.15578356.39103809.1617221476-1249587088.1602698853" target="_blank" rel="noreferrer" className="footer-link w-inline-block">
                  <div>Legal &amp; Privacy Policy</div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="main-container"></div>
      </div>
    </Layout>
  )
}

export default IndexPage
